.tenants-app-bar {
  box-shadow: none;
  z-index: 1029;
  position: absolute;
  background-color: transparent;
  border-bottom: 0;
  margin-bottom: 0;
  width: 100%;
  padding-top: 10px;
}

.tenants-list-title {
  padding: 30px 30px 10px 30px;
  > h3,
  h4 {
    color: $color-white;
  }

  > h4 {
    padding-top: 10px;
  }
}

.tenants-list-container {
  height: 100vh;
  display: flex;
  margin: 0;
  background-image: url("../../Shared/Assets/sidebar-background.jpg");
  background-size: cover;
  background-position: center;
  align-items: center;
  justify-content: center;
  color: $color-white;

  .tenants-list-card {
    display: flex;
    min-height: 250px;
    width: 400px;
    padding: 10px;
    overflow-y: auto;

    background-color: rgba($color-black, 0.7);
  }

  .tenants-list-card-small {
    width: 100%;
    margin-left: 10px;
    margin-right: 10px;
  }

  .tenants-list {
    width: 100%;
    > div {
      background-color: $color-white;
      opacity: 0.7;

      border-radius: 5px;
      margin-bottom: 5px;
      span {
        color: $color-black;
      }
      &:hover {
        background-color: $vulcan-yellow-1235C !important;
        opacity: 1;
        span {
          color: $color-white !important;
        }
      }
    }
  }
}

.tenants-logo-container {
  position: relative;
  padding: 15px 0px;
  margin: 0;
  display: flex;
  align-items: center;
  flex-direction: row;
  z-index: 4;

  > a {
    color: $color-black;

    &:hover,
    &:focus {
      color: $color-black;
    }
  }
}

.tenants-logo-img {
  margin-left: 22px;
  margin-right: 18px;

  > img {
    width: 50px;
    vertical-align: middle;
    border: 0;
  }
}

.tenants-logo-text {
  text-transform: uppercase;
  padding: 5px 0px;
  font-size: 24px;
  white-space: nowrap;
  font-weight: 400;
  line-height: 30px;
  overflow: hidden;
}

.dropdownItemWithIcon {
  display: flex;
  flex-direction: row;

  svg {
    margin-right: 0;
    margin-left: auto;
    color: $color-black;
  }

  &:hover {
    svg {
      color: $color-white;
    }
  }
}

.tenants-select-menu-item {
  font-size: inherit !important;
}
