/* style rules are marked as important to prevent material-ui from overriding them. */
.load-more-section {
    height: $footer-height;
    min-height: $footer-height;
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    margin-left: 0 !important;
    margin-right: 0;
    width: 100%;
    background: none !important;
    color: $vulcan-blue-299 !important;
    box-shadow: none !important;
    margin: 0 !important;
}

