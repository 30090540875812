.click-product {
  text-decoration: underline;
  cursor: pointer;
}

.fieldset {
  padding: 15px 0px 0px 5px;
  border-color: rgba(0, 0, 0, 0.2);
  border-width: 1px;

  .label {
    padding: 0 5px;
    margin-bottom: 0;
    text-transform: uppercase;
    font-size: 2em;
    font-weight: 400;

    .small {
      margin: 0;
      font-size: 0.5em;
      text-transform: none;
    }
  }

  span {
    margin: 0px 5px;
    font-size: 1em;
    line-height: 2em;
    font-weight: 500;
  }
}

.table-narrow {
  .rt-tr {
    margin: 0;
    height: auto;
    flex: 1 1 auto;
    .rt-td {
      padding: 0px;
    }
  }
}

.react-table-divider > .rt-table > .rt-thead {
  border-bottom: none;
  box-shadow: #e0e0e0 0 0 0 3px inset;
}

.react-table-divider > .rt-table > .rt-tbody > .rt-tr-group:last-child {
  border-top: none;
}

.ReactTable .rt-tbody .rt-tr-group {
  border-bottom: none;
  box-shadow: rgba(0, 0, 0, 0.05) 0px -2px 0px -1px inset;
}

.fieldset {
  border: none;
}
