.react-table {
  .rt-tr {
    > div {
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start; /* horizontally align items in left */
      align-items: center; /* vertically align items in center */
    }
  }

  .rt-td:first-child {
    > div {
      justify-content: flex-start;
    }
  }

  .rt-thead {
    .rt-th {
      font-weight: 500;
      text-align: left;
    }
    .rt-th.-cursor-pointer {
      > div:first-of-type:after {
        top: 5px !important;
        right: 0 !important;
        background-repeat: no-repeat;
        content: url("../Assets/sort-up-arrow.svg") !important;
      }
    }

    .rt-th.-cursor-pointer.-sort-asc {
      > div:first-of-type:after {
        top: 5px !important;
        right: 0 !important;
        content: url("../Assets/sort-up-arrow.svg") !important;
      }
    }

    .rt-th.-cursor-pointer.-sort-desc {
      > div:first-of-type:after {
        top: 5px !important;
        right: 0 !important;
        content: url("../Assets/sort-down-arrow.svg") !important;
      }
    }
  }
}

.react-table-divider > .rt-table {
  > .rt-thead {
    border-bottom: 1px solid rgba(224, 224, 224, 1);
  }

  > .rt-tbody {
    > .rt-tr-group:last-child {
      border-top: 1px solid rgba(224, 224, 224, 1);
    }
  }
}

.table-font {
  .rt-tr {
    margin: 0;
    height: auto;
    flex: 1 1 auto;
    .rt-td {
      padding: 7px 0px;
      font-size: 1rem;
      line-height: 1rem;
      font-weight: 450;
    }
  }
}

.react-table-sticky > .rt-table {
  overflow: visible;

  > .rt-tr {
    min-width: fit-content;
  }

  > .-header {
    overflow-x: hidden;
    background-color: #f5f5f5;
    border-radius: 10px;
    margin-top: 10px;
    position: sticky;
    position: -webkit-sticky;
    top: 0px;
    z-index: 1;
    min-width: unset !important;
  }

  > .-filters {
    overflow-x: hidden;
    background-color: white;
    position: -webkit-sticky;
    position: sticky;
    top: 55px;
    z-index: 1;
    min-width: unset !important;
  }

  > .rt-tbody {
    overflow-x: auto;
    min-width: unset !important;
  }
}

.table-font {
  .rt-tr {
    margin: 0;
    height: auto;
    flex: 1 1 auto;
    .rt-td {
      font-size: 1rem;
      line-height: 1rem;
      font-weight: 450;
    }
  }
}
