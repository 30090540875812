.product-class-cell {
    color: white;
    padding: 3px;
    font-weight: bold;
    width: 40px;
    text-align: center;
}

.purchase-line-status-cell {
    color: black;
    padding: 5px 10px;
    box-sizing: border-box;
    font-style: oblique;
    font-weight: 700;
    border-radius: 10px;
    text-align: center;
}

.facets-panel {
    border: 1px solid rgba(0, 0, 0, 0.2);
    width: 100%;
    margin: 0px 5px 0 15px !important;
    overflow-y: auto;
    height: 74vh;

    .label-panel {
        background: rgba(0, 0, 0, 0.1);
        margin: 0px -15px;
        padding: 0px 15px;
        .label {
            font-size: 1.2rem;
            line-height: 2.5rem;
        }
    }

    .item {
        margin: 5px 0;
        font-size: 0.875rem;
        font-weight: 400;
    }
    .item:hover {
        text-decoration: underline;
        cursor: pointer;
    }
}

.count-row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
        padding: 1px 2px;
    }
}
