.pdf-view-container {
    height: calc(100% - 50px);
    display: flex;
    align-items: center;
}

.content-not-supported {
    @extend %empty-component-shared;
    display: flex;
    width: 100%;
    height: 100%;
    padding: 0 15px 0 15px;
    align-items: center;
    justify-content: center;
    a {
        color: $primaryColor;
    }
}

.supplier-invoices-loading-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}


.attachment-container {
    object-fit: none;
    height: 100%;
    width: 100%;

    object {
        object-fit: inherit;
        height: 100%;
        width: 100%;
    }

    .unexpected-error-invoice-attachment {
        height: 100%;
        width: 100%;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

