.card {
    .card-icon {
      svg {
        color: $color-black;
      }
    }

    .card-header {
      display: flex;
      flex-direction: row;
      align-items: flex-start;

      .card-header-section {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 15px;
        flex-wrap: wrap;
        flex-grow: 1;

        > button {
          margin-left: 10px;
        }

        .card-title {
          padding-left: 10px;
          padding-right: 10px;
          font-size: 25px;
          color: #3c4858;
          margin-top: 0px;
          margin-bottom: 0px;
          min-height: auto;
          font-weight: 300;
          font-family: "Roboto", "Helvetica", "Arial", sans-serif;
          text-decoration: none;
          flex-grow: 5;
        }
      }

      .card-header-setting-fieldset {
        border-color: rgba(0, 0, 0, 0.2);
        border-width: 1px;
        border-radius: 5px;
        margin: 5px;
        padding: 10px 0px 10px 15px;

        > label {
          color: #3c4858;
          font-weight: 400;
        }
      }
    }
  }