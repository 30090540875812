
.product-maps-table-cell {
    overflow: hidden;
}

.product-maps-card-header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.search-product-maps {
    padding-bottom: 0;
    margin-bottom: 0;
    padding-right: 5px;
    flex-grow: 1;
    width: 300px;

    > div {
         padding-top: 0;

        > div {
            width: 100%;
        }
    }

    input {
        padding-bottom: 6px;
    }
}

.productmaps-table {
    border: none;
    .rt-td {
        margin: 0;
        height: 50px;

        > div {
            height: 100%;
            display: flex;
            align-items: center;
        }
    }
    .rt-thead {
        .rt-th {
            font-weight: 500;
        }
        .rt-th.-cursor-pointer {
            > div:first-of-type:after {
                top: 0px;
                background-repeat: no-repeat;
                content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTAgMTVWNmw0IDQgMS0xLTYtNi02IDYgMSAxIDQtNHY5eiIgZmlsbD0iIzMzMyIvPjwvc3ZnPg==);
            }
        }

        .rt-th.-cursor-pointer.-sort-asc {
            > div:first-of-type:after {
                content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTAgMTVWNmw0IDQgMS0xLTYtNi02IDYgMSAxIDQtNHY5eiIgZmlsbD0iIzMzMyIvPjwvc3ZnPg==);
            }
        }

        .rt-th.-cursor-pointer.-sort-desc {
            > div:first-of-type:after {
                content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNOCAzdjkuMTNMNCA4IDMgOWw2IDYgNi02LTEtMS00IDQuMTNWM3oiIGZpbGw9IiMzMzMiLz48L3N2Zz4=);
            }
        }
    }
}

.product-map-modal {
    > div {
        > div {
            min-height: 550px;
        }
    }
}

.add-modal-footer {
    position: absolute;
    bottom: 5px;
    right: 5px;
}

.add-product-map-form {
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 5px;

    .product-map-form-body {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        > div {
            flex: 1;
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    .form-group-custom {
        > div {
            margin-left: 5px;

            > div {
                width: 100%;
            }
        }

        > label {
            white-space: nowrap;
            width: 30%;
        }

        input {
            width: 70%;
        }
    }
}

.modal-content-root {
    overflow-y: visible !important;
}

.add-codemap-button {
    max-width: 300px;
}

.editable-cell {
    padding-left: 5px;
    border: 1px solid $primaryColor;
    border-radius: 5px;

    &.invalid-cell {
        border-color: $color-red;
    }
}

.view-cell {
    border: none;
}

.maps-table-card {
    .card-icon {
        svg {
            color: $color-black;
        }
    }
}

.add-code-map-container {
    text-align: right;
}

.actions-cell-buttons-container {
    .actions-cell-save-icon {
        svg {
            color: $color-green !important;
        }
    }

    .actions-cell-close-icon {
        svg {
            color: $color-black !important;
        }
    }

    .actions-cell-edit-icon {
        svg {
            color: $color-black !important;
        }
    }
}

.rc-tree {
    .filter-node {
        .rc-tree-node-content-wrapper {
            span {
                color: $primaryColor !important;
            }
        }
    }
}

.pagination-bottom{
    margin-top: 20px;
}
