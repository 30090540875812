.add-modal-footer {
    position: relative;
}

.add-modal-button-group {
    position: relative;
        /*
        Fixed width is used here to give a consistent width to the
        button and the prgoress bar that appears on top of the button.
    */
    width: 150px;
    button {
        width: 150px;
    }
    .add-exception
    {
        position: absolute;
        top: 0;
        width: 100%;
        right: -1px;
    }
}