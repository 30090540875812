@import "src/Shared/Styles/variables";
@import "src/Shared/Styles/colors";
@import "src/Shared/Styles/common";
@import "src/Shared/Styles/card";
@import "src/Shared/Styles/table";
@import "src/App/appStyles.scss";
@import "src/Invoice/supplierInvoiceStyles";
@import "src/ProductMaps/Modal/modal";
@import "src/ProductMaps/_productMaps";
@import "src/Reorder/style";
@import "src/ProcurementLine/style";
@import "src/ie11Styles";

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

body {
  overflow: hidden;
  background-color: #eeeeee;
  color: #3c4858;
  font-size: 14px;
}

html,
body {
  margin: 0;
  height: 100%;
}
body {
  height: 100%;
}

#root {
  height: 100%;
}

footer {
  padding: 0px !important;
}

.container {
  margin-top: 30px; // This is fix for position: sticky not work nice on Edge
  height: calc(100% - 30px);
  overflow-y: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 30px;
}
