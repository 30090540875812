.supplier-invoice-details-container {
    position: relative;
    -webkit-flex: 5;
    flex: 5;
    width: calc(100% - 400px);

    display: flex;
    flex-direction: column;

    .supplier-invoice-details {
        border-bottom: 1px solid $color-light-grey;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 15px;

        > div {
            display: flex;
            flex-direction: column;
            flex: 0 1 50%;
            line-height: 1.5;
            .item-header {
                margin-right: 5px;
                font-weight: bold;
            }
        }
    }
    .invoice-lineitems-container {
        .invoice-line-item-container {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding-top: 5px;
            padding-bottom: 5px;
            line-height: 1.5;
            border-bottom: 1px solid $color-light-grey;
            justify-content: space-between;

            .left-column {
                > div:first-child {
                    font-size: 20px;
                    font-weight: 400;
                }

                > div:nth-child(2) {
                    font-size: 20px;
                    font-weight: 300;
                }

                > div::nth-child(3) {
                    font-size: 15px;
                    font-weight: 200;
                }
            }

            .right-column {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
            }
        }
    }
}

.supplier-invoice-details-navigation {
    position: absolute;
    width: 100%;
    bottom: 0;
    text-align: center;
    border-top: 1px solid $color-light-grey;

    button {
        background-color: $color-white;
        width: 48px;
        height: 48px;
        margin: 2px;

        svg {
            width: 24px;
            height: 24px;
        }

        &:hover, &:focus {
            background-color: lighten($vulcan-yellow-109C, 30%);
        }

        &.selected {
            background-color: $vulcan-yellow-109C;
        }
    }
}

.details-view-container {
    flex: 1;
}