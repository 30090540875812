.supplier-invoices-container {
    height: 100%;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    width: 400px;
    position: relative;

    border-right: 1px solid $color-light-grey;

    > ul {
        list-style: none;
        width: 100%;
        padding: 0;
        margin: 0;
        overflow: auto;
        height: calc(100% - #{$header-height});
    }

    .search-supplier-invoices {
        width: 100%;

        input::-ms-clear {
            display: none;
        }
    }
}

.invoice-list-item {
    cursor: pointer;
    border-bottom: 1px solid !important;
    border-bottom-color: $color-light-grey !important;

    .invoice-card {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        line-height: 1.5;

        .left-column {
            > div:nth-child(1) {
                font-weight: 400;
                font-size: 20px;
            }

            > div:nth-child(2) {
                font-weight: 300;
                font-size: 15px;
            }
        }

        .right-column {
            font-weight: 200;
            font-size: 13px;
            text-align: right;
        }
    }

    &.selected {
        background-color: $color-light-grey;
    }
}

.supplier-invoices-list-header {
    display: flex;
    flex-direction: row;
    height: $header-height;
    min-height: $header-height;

    .supplier-invoices-list-progressbar {
        position: absolute;
        width: 100%;
        top: $search-supplier-invoices-height;
    }
}

.filter-list {
    .filter-list-item {
        &:hover {
            background-color: lighten($primaryColor, 30%) !important;
        }
    }
}

