#site-container-id {
    height: 100%;
}

.site-content-and-footer-container {
    margin-top: $headerHeight;
    height: calc(100% - #{$headerHeight});
    > div {
        margin-top: 0;
        padding: 0 !important;
        position: relative;
        height: 100%;

        > div {
            position: absolute;
            height: 100%;
            width: 100%;
            padding: 0 !important;
            margin: 0 !important;
        }
    }
}

.setting-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    text-align: center;

    .title {
        width: 100%;
        text-align: justify;
        font-weight: bold;
        font-size: 20px;
        color: $color-grey;
        margin: 20px;
    }

    .section {
        flex: 0 0 80%;
        flex: 0 0 80%;
        text-align: left;
        margin: 0px 20px;

        > span {
            font-size: 12px;
            font-weight: 500;
        }

        .section-title {
            font-size: 12px;
            font-weight: 500;
            color: black;
            font-family: "Segoe UI", "Helvetica Neue", sans-serif;
        }
    }
}

.setting-footer-container {
    position: absolute;
    bottom: 25px;
    width: 100%;
    text-align: center;
    display: flex;
    flex-wrap: wrap;

    .title {
        width: 100%;
        text-align: justify;
        font-weight: bold;
        font-size: 20px;
        color: $color-grey;
        margin: 20px;
    }

    .logo {
        fill: currentColor;
        flex: 0 0 30%;
        max-width: 30%;
        height: 60px;
        padding: 0 0 0 20px;
        transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }

    .details {
        flex: 0 0 70%;
        max-width: 70%;
        padding: 0 0 0 10px;

        > span {
            text-align: justify;
            display: block;
            font-size: 12px;
            font-weight: 500;
            line-height: 20px;
        }
    }

    .setting {
        flex: 0 0 80%;
        flex: 0 0 80%;
        text-align: left;
        margin: 0px 20px;

        > span {
            font-size: 12px;
            font-weight: 500;
        }
    }
}
