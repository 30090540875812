@import "./Stock/style";

.product-class-cell {
  color: white;
  padding: 3px;
  font-weight: bold;
  width: 40px;
  text-align: center;
}

.export-excel-button,
.filter-apply-button {
  width: 140px;
}

.filter-apply-button {
  margin-top: 18px !important;
  margin-right: 17px !important;
}

.filter-apply-button {
  margin-top: 18px !important;
  margin-right: 17px !important;
}

.export-excel-button,
.filter-apply-button {
  width: 140px;
}

.wordwrap {
  white-space: pre-line !important;
  word-wrap: break-word;

  div {
    line-height: 1em;
    font-size: 1rem;
    text-align: right !important;
  }
}

.wordwrap-alignLeft {
  white-space: pre-line !important;
  word-wrap: break-word;

  div {
    line-height: 1em;
    font-size: 1rem;
    text-align: left !important;
  }
}

.expend-row {
  display: flex;
  justify-content: flex-start;
  margin-top: 0px;

  .expend-row-button {
    width: 110px;
    padding: 5px;
    margin: 0 5px;

    span {
      font-size: 0.7rem;
    }
  }
}

.expend-row-green-button {
  width: 110px;
  padding: 5px;
  margin: 0 5px;
  // This is annoying, but we need to do this as the vulcan theme doesn't let us change the colour easily.
  background-color: #046307 !important;

  span {
    font-size: 0.7rem;
  }
}

.footer-button-row {
  display: flex;
  justify-content: flex-start;
  align-self: flex-start;
}

.ReactTable .rt-expander {
  display: inline-block;
  position: relative;
  margin: 0;
  color: transparent;
  margin: 0 5px;
}

.ReactTable .rt-expander:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-90deg);
  border-left: 5.04px solid transparent;
  border-right: 5.04px solid transparent;
  border-top: 7px solid rgba(0, 0, 0, 0.8);
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  cursor: pointer;
}
.ReactTable .rt-expander.-open:after {
  transform: translate(-50%, -50%) rotate(0);
}

.ReactTable .rt-tbody {
  overflow: hidden;
}

// hide the input up/down arrow dials from input
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number]{
  text-align: right;
}

.detailsModal{
  margin: 50px;
  font-size: 1.3em;
  line-height: 1.5em;
}

.product-form-label-title {
  color: black;
  font-size: 1.2vh;
  opacity: 0.8;
}

.reviewed-icon {
  height: 80px;
  width: 80px;
}

.product-dialog-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.reviewed-label-title {
  color: black;
  font-size: 2vh;
  opacity: 0.8;
}