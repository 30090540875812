$header-height: 55px;
$footer-height: 30px;
$search-supplier-invoices-height: 40px;

@import './Shared/_loadMoreDataStyles';
@import './DetailsSection/DetailsView/InvoiceLines/_invoiceLinesStyles';
@import './DetailsSection/AttachmentView/_attachmentViewStyles';
@import './DetailsSection/_detailsSectionStyles';
@import './ListSection/_listSectionStyles';

.supplier-invoices-layout {
    position: absolute;
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: row;
}

.form-group-custom {

    &.search-supplier-invoices {
        min-height: $search-supplier-invoices-height;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 15px;
        padding-right: 15px;
        margin-bottom: 0;

        > div {
            padding-top: 0;

            > div {
                width: 100%;
            }
        }
    }
}

%empty-component-shared {
    font-weight: bold;
    font-size: 20px;
    text-align: center;
}

.no-items-selected,
.empty-search-results,
.no-invoice-line-items,
.no-supplier-invoices {
    @extend %empty-component-shared;
}

.no-items-selected {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @extend %empty-component-shared;
}


@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeBorder {
    from {
        border-bottom: 3px solid $vulcan-yellow-158C;
    }
    to {
        border-bottom: 1px solid $color-light-grey;
    }
}
 
.fade-in {
    animation: fadeIn .1s ease-in, fadeBorder 1s ease-in;
}
